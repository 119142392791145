//Design by DungTran
'use strict';

var scrollToTop = (function ($, window, document) {

    var settings = {
        element: '#scroll-to-top',
        setScrollPosition: 50,
        debug: false
    };

    var elementsRegister = {
        $document: $(document),
        $window: $(window)
    };

    var init = function ($options) {

        $options = $options || {};

        $.extend(true, settings, $options);

        var dHeight = elementsRegister.$document.height();
        var scrollSetPosition = dHeight * 50 / 100;
        var scrollTrigger = $(settings.element);

        // Hide on default if not set display none
        scrollTrigger.hide();

        function checkPosition() {

            if (settings.debug) {
                console.log(dHeight, elementsRegister.$window.scrollTop(), scrollSetPosition);
            }

            if (elementsRegister.$window.scrollTop() > scrollSetPosition) {
                scrollTrigger.fadeIn();
            } else {
                scrollTrigger.fadeOut();
            }
        }

        checkPosition();

        elementsRegister.$window.scroll(function () {
            checkPosition();
        });

        scrollTrigger.click(function (e) {
            e.preventDefault();
            $('body,html').animate({
                scrollTop: 0
            }, 800);

        });
    };

    return {
        init: init
    };

})(jQuery, window, document);


(function ($) {
    /*WOW JS*/
    new WOW().init();

    /*Slide Main*/
    $('.slider-banner').slick({
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        autoplaySpeed: 7000,
    });

    $('.slider-interns').slick({
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        autoplaySpeed: 5000,
    });

    scrollToTop.init({
        debug: true
    });

    $('a').smoothScroll();

})(jQuery); // End of use strict

/*POPUP*/

$(document).ready(function () {
    $(".btn-open-popup").click(function (even) {
        even.preventDefault(); // không truy cập đến link trong thẻ a
        loadPopup(); // function show popup
    });
    $("#btn-close").click(function () {
        disablePopup();
    });
    /*$(this).keydown(function(event) {
     if (event.which == 27) { // 27 is 'Ecs' in the keyboard
     disablePopup();  // function close pop up
     }
     });*/
    /*$("#background-popup").click(function() {
     disablePopup();  // function close pop up
     disableLoginPopup();
     });*/
    var popupStatus = 0; // set value
    function loadPopup() {
        if (popupStatus == 0) { // if value is 0, show popup
            $("#to-popup").fadeIn(200); // fadein popup div
            $("#background-popup").css("opacity", "0.8"); // css opacity, supports IE7, IE8
            $("#background-popup").fadeIn(200);
            popupStatus = 1; // and set value to 1
        }
    }

    function disablePopup() {
        if (popupStatus == 1) { // if value is 1, close popup
            $("#to-popup").fadeOut(300);
            $("#background-popup").fadeOut(300);
            $('body,html').css("overflow", "auto");//enable scroll bar
            popupStatus = 0;  // and set value to 0
        }
    }

    $(window).bind('scroll', function () {
        if ($(window).scrollTop() >= 300) {
            $(".utn-fixed").addClass("btn-show");
        }
        else {
            $(".utn-fixed").removeClass("btn-show");
        }
    });

    var checksearch = 0;

    $(".fa-search-top").click(function (even) {
        even.preventDefault(); // không truy cập đến link trong thẻ a
        if(checksearch == 0) {

            $(".form-search-top").fadeIn(600);
            $(".input-seach-top").focus();
            checksearch = 1;
        }
        else {
            $(".form-search-top").fadeOut(600);
            checksearch = 0;
        }
    });
    $(".input-seach-top").mousemove(function () {
        $(".input-seach-top").focus();
    });
    document.onkeydown = function(evt) {
        evt = evt || window.event;
        var isEscape = false;
        if ("key" in evt) {
            isEscape = (evt.key == "Escape" || evt.key == "Esc");
        } else {
            isEscape = (evt.keyCode == 27);
        }
        if (isEscape) {
            $(".form-search-top").fadeOut(600);
            checksearch = 0;
        }
    };
});

(function() {
    var header = new Headroom(document.querySelector("#mainNav"), {
        tolerance: 5,
        offset : 205,
        classes: {
            initial: "animated",
            pinned: "slideDown",
            unpinned: "slideUp"
        }
    });
    header.init();
}());





